import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Layout from "../layout/layout";
import Breadcrumbs from "../components/breadcrumbs";

function Index(props) {
    return (
        <Layout>
            <Breadcrumbs link1="TekRevol" link2="Terms Conditions"/>
            <div className="terms-conditions p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                            <h1>Terms and Conditions for TekRevol</h1>
                            <p>These terms and conditions ("Terms", "Agreement") are an agreement between Website
                                Operator
                                ("Website Operator", "us", "we" or "our") and you ("User", "you" or "your"). This
                                Agreement sets
                                forth the general terms and conditions of your use of the tekrevol.com website and any
                                of its
                                products or services (collectively, "Website" or "Services").</p>
                            <p> These Website Standard Terms and Conditions written on this webpage shall manage your
                                use of our
                                website, Tekrevol accessible at https://www.tekrevol.com/.</p>
                            <p>These Terms will be applied fully and affect to your use of this Website. By using this
                                Website,
                                you agreed to accept all terms and conditions written in here. You must not use this
                                Website if
                                you disagree with any of these Website Standard Terms and Conditions.
                            </p>

                            <h2>Restrictions</h2>
                            <p><strong>You are specifically restricted from all of the following:</strong></p>
                            <ul>
                                <li>Publishing any Website material in any other media;</li>
                                <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
                                <li>Publicly performing and/or showing any Website material;</li>
                                <li>Using this Website in any way that is or may be damaging to this Website;</li>
                                <li>Using this Website in any way that impacts user access to this Website;</li>
                                <li>Using this Website contrary to applicable laws and regulations, or in any way may
                                    cause
                                    harm to the Website, or to any person or business entity;
                                </li>
                                <li>Engaging in any data mining, data harvesting, data extracting or any other similar
                                    activity
                                    in relation to this Website;
                                </li>
                                <li>Using this Website to engage in any advertising or marketing.</li>

                            </ul>
                            <p>Certain areas of this Website are restricted from being access by you and Tekrevol LLC
                                may
                                further restrict access by you to any areas of this Website, at any time, in absolute
                                discretion. </p>
                            <h3>Age requirement</h3>
                            <p>You must be at least 16 years of age to use this Website. By using this Website and by
                                agreeing
                                to this Agreement you warrant and represent that you are at least 18 years of age.</p>
                            <h4>Links to other websites</h4>
                            <p>Although this Website may link to other websites, we are not, directly or indirectly,
                                implying
                                any approval, association, sponsorship, endorsement, or affiliation with any linked
                                website,
                                unless specifically stated herein. We are not responsible for examining or evaluating,
                                and we do
                                not warrant the offerings of, any businesses or individuals or the content of their
                                websites. We
                                do not assume any responsibility or liability for the actions, products, services, and
                                content
                                of any other third-parties. You should carefully review the legal statements and other
                                conditions of use of any website which you access through a link from this Website. Your
                                linking
                                to any other off-site websites is at your own risk.</p>
                            <h4>Prohibited uses</h4>
                            <p>In addition to other terms as set forth in the Agreement, you are prohibited from using
                                the
                                Website or its Content: (a) for any unlawful purpose; (b) to solicit others to perform
                                or
                                participate in any unlawful acts; (c) to violate any international, federal, provincial
                                or state
                                regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our
                                intellectual
                                property rights or the intellectual property rights of others; (e) to harass, abuse,
                                insult,
                                harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual
                                orientation, religion, ethnicity, race, age, national origin, or disability; (f) to
                                submit false
                                or misleading information; (g) to upload or transmit viruses or any other type of
                                malicious code
                                that will or may be used in any way that will affect the functionality or operation of
                                the
                                Service or of any related website, other websites, or the Internet; (h) to collect or
                                track the
                                personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or
                                scrape;
                                (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the
                                security
                                features of the Service or any related website, other websites, or the Internet. We
                                reserve the
                                right to terminate your use of the Service or any related website for violating any of
                                the
                                prohibited uses.</p>
                            <h4>Intellectual property rights</h4>
                            <p>This Agreement does not transfer to you any intellectual property owned by Website
                                Operator or
                                third-parties, and all rights, titles, and interests in and to such property will remain
                                (as
                                between the parties) solely with Website Operator. All trademarks, service marks,
                                graphics and
                                logos used in connection with our Website or Services, are trademarks or registered
                                trademarks
                                of Website Operator or Website Operator licensors. Other trademarks, service marks,
                                graphics and
                                logos used in connection with our Website or Services may be the trademarks of other
                                third-parties. Your use of our Website and Services grants you no right or license to
                                reproduce
                                or otherwise use any Website Operator or third-party trademarks.</p>
                            <h4>Limitation of liability</h4>
                            <p>To the fullest extent permitted by applicable law, in no event will Website Operator, its
                                affiliates, officers, directors, employees, agents, suppliers or licensors be liable to
                                any
                                person for (a): any indirect, incidental, special, punitive, cover or consequential
                                damages
                                (including, without limitation, damages for lost profits, revenue, sales, goodwill, use
                                of
                                content, impact on business, business interruption, loss of anticipated savings, loss of
                                business opportunity) however caused, under any theory of liability, including, without
                                limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise,
                                even if
                                Website Operator has been advised as to the possibility of such damages or could have
                                foreseen
                                such damages. To the maximum extent permitted by applicable law, the aggregate liability
                                of
                                Website Operator and its affiliates, officers, employees, agents, suppliers and
                                licensors,
                                relating to the services will be limited to an amount greater of one dollar or any
                                amounts
                                actually paid in cash by you to Website Operator for the prior one month period prior to
                                the
                                first event or occurrence giving rise to such liability. The limitations and exclusions
                                also
                                apply if this remedy does not fully compensate you for any losses or fails of its
                                essential
                                purpose.</p>
                            <h4>Indemnification</h4>
                            <p>You agree to indemnify and hold Website Operator and its affiliates, directors, officers,
                                employees, and agents harmless from and against any liabilities, losses, damages or
                                costs,
                                including reasonable attorneys' fees, incurred in connection with or arising from any
                                third-party allegations, claims, actions, disputes, or demands asserted against any of
                                them as a
                                result of or relating to your Content, your use of the Website or Services or any
                                willful
                                misconduct on your part.</p>
                            <h4>Severability</h4>
                            <p>All rights and restrictions contained in this Agreement may be exercised and shall be
                                applicable
                                and binding only to the extent that they do not violate any applicable laws and are
                                intended to
                                be limited to the extent necessary so that they will not render this Agreement illegal,
                                invalid
                                or unenforceable. If any provision or portion of any provision of this Agreement shall
                                be held
                                to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the
                                intention of the parties that the remaining provisions or portions thereof shall
                                constitute
                                their agreement with respect to the subject matter hereof, and all such remaining
                                provisions or
                                portions thereof shall remain in full force and effect.</p>
                            <h4> Dispute resolution</h4>
                            <p>The formation, interpretation, and performance of this Agreement and any disputes arising
                                out of
                                it shall be governed by the substantive and procedural laws of California, United States
                                without
                                regard to its rules on conflicts or choice of law and, to the extent applicable, the
                                laws of
                                United States. The exclusive jurisdiction and venue for actions related to the subject
                                matter
                                hereof shall be the courts located in California, United States, and you hereby submit
                                to the
                                personal jurisdiction of such courts. You hereby waive any right to a jury trial in any
                                proceeding arising out of or related to this Agreement. The United Nations Convention on
                                Contracts for the International Sale of Goods does not apply to this Agreement.</p>
                            <h4> Changes and amendments</h4>
                            <p>We reserve the right to modify this Agreement or its policies relating to the Website or
                                Services
                                at any time, effective upon posting of an updated version of this Agreement on the
                                Website. When
                                we do, we will send you an email to notify you. Continued use of the Website after any
                                such
                                changes shall constitute your consent to such changes. Policy was created with
                                https://www.WebsitePolicies.com</p>

                            <h4>Acceptance of these terms</h4>
                            <p> You acknowledge that you have read this Agreement and agree to all its terms and
                                conditions. By
                                using the Website or its Services you agree to be bound by this Agreement. If you do not
                                agree
                                to abide by the terms of this Agreement, you are not authorized to use or access the
                                Website and
                                its Services.</p>

                            <h4>Contacting us</h4>
                            <p>If you would like to contact us to understand more about this Agreement or wish to
                                contact us
                                concerning any matter relating to it, you may send an email to info@tekrevol.com</p>


                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
}

export default Index;